import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import { useTranslation } from "react-i18next";
import schoolProjects from "./SchoolProjects.json";
import projects from "./Projects.json";
import featuredProjects from "./FeaturedProjects.json";
import i18next from "i18next";

function Projects() {
  const { t } = useTranslation();
  const currentLang = i18next.language;
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          <strong className="purple">{t("FeaturedProjects")}</strong>
        </h1>
        <p style={{ color: "white" }}>{t("FeaturedProjectsDesc")}</p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          {featuredProjects.map((item) => {
            return (
              <Col md={4} className="project-card">
                <ProjectCard
                  imgPath={item.image}
                  title={item.name}
                  description={
                    currentLang === "fr" ? item.description : item.descriptionEn
                  }
                  ghLink={item.url}
                  tech={item.tech}
                />
              </Col>
            );
          })}
        </Row>
        <h1 className="project-heading">
          <strong className="purple">{t("MyProjects")}</strong>
        </h1>
        <p style={{ color: "white" }}>{t("ProjectsDesc")}</p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          {projects.map((item) => {
            return (
              <Col md={4} className="project-card">
                <ProjectCard
                  imgPath={item.image}
                  isBlog={false}
                  title={item.name}
                  description={
                    currentLang === "fr" ? item.description : item.descriptionEn
                  }
                  ghLink={item.url}
                  tech={item.tech}
                />
              </Col>
            );
          })}
        </Row>
        <h1 className="project-heading">
          <strong className="purple">{t("SchoolProjects")}</strong>
        </h1>
        <p style={{ color: "white" }}>{t("SchoolProjectsDesc")}</p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          {schoolProjects.map((item) => {
            return (
              <Col md={4} className="project-card">
                <ProjectCard
                  imgPath={item.image}
                  isBlog={false}
                  title={item.name}
                  description={
                    currentLang === "fr" ? item.description : item.descriptionEn
                  }
                  ghLink={item.url}
                  tech={item.tech}
                />
              </Col>
            );
          })}
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
