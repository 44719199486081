import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import quentin from "../../Assets/quentin.png";
import Tilt from "react-parallax-tilt";
import { AiFillGithub } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import MarkdownRenderer from "react-markdown-renderer";

function Home() {
  const { t } = useTranslation();
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={10} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              {t("LET ME")}
              <span className="purple"> {t("INTRODUCE")} </span> {t("MYSELF")}
            </h1>
            <p className="home-about-body">
              <MarkdownRenderer markdown={t("presentation1")} />
              <br />
              <br />
              <MarkdownRenderer markdown={t("presentation2")} />
              <br />
              <br />
              <MarkdownRenderer markdown={t("highlightTitle")} />
              <br />
              <br />
              <MarkdownRenderer markdown={t("highlights")} />
            </p>
          </Col>
          <Col md={2} className="myAvtar">
            <Tilt>
              <img src={quentin} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>{t("FIND ME ON")}</h1>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/quentingruber"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/quentin-gruber/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home;
