import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import { CgFlag } from "react-icons/cg";
import {
  AiOutlineHome,
  AiOutlineFundProjectionScreen,
  AiOutlineUser,
} from "react-icons/ai";

import { CgFileDocument } from "react-icons/cg";

import { useTranslation } from "react-i18next";
import i18next from "i18next";
import pdf from "../Assets/cv-gruber-quentin.pdf";
import pdf_eng from "../Assets/cv-gruber-quentin-en.pdf";
function swapLang() {
  const currentLang = i18next.language;
  if (currentLang === "en") {
    i18next.changeLanguage("fr");
  } else {
    i18next.changeLanguage("en");
  }
}
function NavBar() {
  const { t } = useTranslation();
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener("scroll", scrollHandler);

  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? "sticky" : "navbar"}
    >
      <Container>
        {/* <Navbar.Brand href="/" className="d-flex"> */}
        {/*   <img src={logo} className="img-fluid logo" alt="brand" /> */}
        {/* </Navbar.Brand> */}
        {/* <Navbar.Toggle */}
        {/*   aria-controls="responsive-navbar-nav" */}
        {/*   onClick={() => { */}
        {/*     updateExpanded(expand ? false : "expanded"); */}
        {/*   }} */}
        {/* > */}
        {/*   <span></span> */}
        {/*   <span></span> */}
        {/*   <span></span> */}
        {/* </Navbar.Toggle> */}
        {/* <Navbar.Collapse id="responsive-navbar-nav"> */}
        <Nav className="ms-auto" defaultActiveKey="#home">
          <Nav.Item>
            <Nav.Link as={Link} to="/" onClick={() => updateExpanded(false)}>
              <AiOutlineHome style={{ marginBottom: "2px" }} /> {t("Home")}
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link
              as={Link}
              to="/about"
              onClick={() => updateExpanded(false)}
            >
              <AiOutlineUser style={{ marginBottom: "2px" }} /> {t("About")}
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link
              as={Link}
              to="/project"
              onClick={() => updateExpanded(false)}
            >
              <AiOutlineFundProjectionScreen style={{ marginBottom: "2px" }} />{" "}
              {t("Projects")}
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link
              as={Link}
              onClick={() => {
                if (i18next.language === "fr") {
                  window.location.href = pdf;
                } else {
                  window.location.href = pdf_eng;
                }
              }}
            >
              <CgFileDocument style={{ marginBottom: "2px" }} /> {t("Resume")}
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link onClick={() => swapLang()}>
              <CgFlag style={{ marginBottom: "2px" }} />{" "}
              {i18next.language === "fr"
                ? "Translate in English"
                : "Traduire en Français"}
            </Nav.Link>
          </Nav.Item>
          {/* <Nav.Item> */}
          {/*   <Nav.Link */}
          {/*     href="https://soumyajitblogs.vercel.app/" */}
          {/*     target="_blank" */}
          {/*     rel="noreferrer" */}
          {/*   > */}
          {/*     <ImBlog style={{ marginBottom: "2px" }} /> Blogs */}
          {/*   </Nav.Link> */}
          {/* </Nav.Item> */}

          {/* <Nav.Item className="fork-btn"> */}
          {/*   <Button */}
          {/*     href="https://github.com/soumyajit4419/Portfolio" */}
          {/*     target="_blank" */}
          {/*     className="fork-btn-inner" */}
          {/*   > */}
          {/*     <CgGitFork style={{ fontSize: "1.2em" }} />{" "} */}
          {/*     <AiFillStar style={{ fontSize: "1.1em" }} /> */}
          {/*   </Button> */}
          {/* </Nav.Item> */}
        </Nav>
        {/* </Navbar.Collapse> */}
      </Container>
    </Navbar>
  );
}

export default NavBar;
