import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { BsGithub } from "react-icons/bs";

function isVideo(path) {
  return path.includes(".mp4") || path.includes(".webm");
}
function ProjectCards(props) {
  return (
    <Card className="project-card-view">
      {props.imgPath ? (
        isVideo(props.imgPath) ? (
          <video className="w-60" autoPlay loop muted>
            <source src={props.imgPath} type="video/mp4" allowFullScreen />
          </video>
        ) : (
          <Card.Img
            variant="top"
            className="w-100 h-60"
            src={props.imgPath}
            alt="card-img"
          />
        )
      ) : null}
      <Card.Body>
        <Card.Title className="mb-2">{props.title}</Card.Title>
        <Card.Subtitle className="mb-2 ">{props.tech}</Card.Subtitle>
        <Card.Text style={{ textAlign: "justify" }}>
          {props.description}
        </Card.Text>
        <Button variant="primary" href={props.ghLink} target="_blank">
          <BsGithub /> &nbsp;
          {"GitHub"}
        </Button>
      </Card.Body>
    </Card>
  );
}
export default ProjectCards;
