import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";
import { useTranslation } from "react-i18next";

function AboutCard() {
  const { t } = useTranslation();
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            {t("hiEveryoneIam")}
            <span className="purple">{t("quentingruber")}</span>
            {t("from")}
            <span className="purple">{t("strasbourgFrance")} </span>
            <br />
            {t("quentinGruberAbout")}
            <br />
            <br />
            {t("ApartFromCodingWhatILike")}
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight />
              {t("movies")}
            </li>
            <li className="about-activity">
              <ImPointRight />
              {t("games")}
            </li>
            <li className="about-activity">
              <ImPointRight />
              {t("adventures")}
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>{t("coolQuote")}</p>
          <footer className="blockquote-footer">{t("me")}</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
